import React, { Component } from "react";
import LanguageContext from './LanguageContext';
import OutboundLink from './OutboundLink';
import './Press.scss';
import PressHeadliner from '../assets/press-headliner.jpg';
import PressFMZine from '../assets/press-fmzine.jpg';

export default class Press extends Component {
	render() {
		const articles = [
			{
				url: 'https://www.headliner.cz/2021-8/roboti-uz-vyhrali',
				title: {
					cs: 'Článek v časopise Headliner (08/2021)',
					en: 'Article in Headliner magazine (Czech) (08/2021)',
				},
				img: PressHeadliner,
			},
			{
				url: 'https://www.fullmoonzine.cz/figurina-s-obrazovkou-jako-frontmanka-cybergrounds',
				title: {
					cs: 'Recenze koncertu ve Full Moon Zine (04/2022)',
					en: 'Concert writeup in Full Moon Zine (04/2022)',
				},
				img: PressFMZine,
			},
		].map((article, index) => <div key={index}>
			<OutboundLink href={article.url} newTab={true}>
			<img src={article.img}/>
			<span>{article.title[this.context.language]}</span>
			</OutboundLink>
		</div>);

		return <div className="press">{articles}</div>;
	}
}
Press.contextType = LanguageContext;
