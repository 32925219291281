import gallery from '../assets/gallery/gallery.json';

export { gallery };
export const thumbnails = require.context('../assets/gallery?thumbnail=true', true, /\.jpe?g$/i);
export const photos = require.context('../assets/gallery', true, /\.jpe?g$/i);

export function collectionByDir(dir) {
	return gallery.collections.find(collection =>
		collection.dir === dir
	);
}

export function photosForCollection(collection) {
	return photos.keys().filter(name => name.startsWith('./' + collection.dir + '/')).sort();
}
