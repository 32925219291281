import React, { Component } from "react";
import LanguageContext from './LanguageContext';
import ShowMore from './ShowMore';
import OutboundLink from './OutboundLink';
import './About.scss';

export default class About extends Component {
	constructor(props) {
		super(props);
		this.state = { more: false, };

		this.showMore_ = this.showMore_.bind(this);
	}

	render() {
		switch(this.context.language) {
			case 'cs': return <>
				<p>Pražská IDM skupina spojující elektronické tracky s&nbsp;živými nástroji a&nbsp;robotickým zpěvem. Taneční
				a&nbsp;zároveň značně experimentální kapela vystupuje s&nbsp;živou rytmikou, saxofonem, mnoha syntezátory
				a&nbsp;robotickou zpěvačkou Otylkou.</p>

				<ShowMore showText="Více informací »" gaEventLabel="About">
					<p>Hudbu Cybergrounds lze popsat jako rozverný soundtrack k&nbsp;postapokalyptickému filmu. Sami členové kapely
					ji označují za "něco jako Idiotape, kdyby hráli na&nbsp;pohřbu Aphexe Twina".</p>

					<p>Projekt založili v&nbsp;roce 2012 saxofonista Filip a&nbsp;baskytarista Ondra s&nbsp;cílem odhalit,
					co jejich hudebnímu cítění mohou poskytnout elektronické nástroje. Postupně dali dohromady materiál pro
					eponymní demo, kombinující chladné elektronické plochy s&nbsp;živými nástroji. Ohlas byl sice omezený, ale
					veskrze pozitivní. Projekt proto pokračoval, skupina začala vystupovat živě a&nbsp;v&nbsp;roce&nbsp;2018
					vydala druhé demo s&nbsp;taneční skladbou DAZ, na&nbsp;koncertech nejlépe přijímaným trackem.</p>

					<p>Po dlouhém hledání kapela začátkem roku&nbsp;2019 přijímá bubeníka Barneyho, někdejšího kmenového člena
					skupin Zputnik a&nbsp;Freak&nbsp;Parade. Živé bicí posouvají zvuk skupiny značně kupředu a&nbsp;kapela natáčí
					desku "Salt", která je průřezem tvorby od samého počátku až do současnosti.</p>

					<p>V roce 2023 vzniká krátké EP s dlouhým názvem "Bound for hell to save the princess and kill the baddies".
						Syrový zvuk skladeb je dílem producenta Dana Frimla.
					</p>

					<p>S četnými připomínkami fanoušků ohledně absence vokalisty se Cybergrounds vypořádali 
					po&nbsp;svém - do kapely přibrali robota Otylku, 3D&nbsp;vizualizovaný model zpěvačky. Jméno Otylka dostala po
					Otýlii Vranské. Také se vejde do dvou kufrů. Její demo video můžete najít{' '}
					<OutboundLink href="https://youtu.be/v9PFHjAfc9o" gaLabel="Youtube" newTab={true}>na&nbsp;YouTube</OutboundLink>.
					</p>
				</ShowMore>
			</>;

			case 'en': return <>
				<p>Prague-based IDM band combining electronic tracks with live instruments and robotic vocals. Dance- and
				experimental- electronica band performs with live drums, bass guitar, saxophones, tons of synths and includes
				robotic singer Otylia.</p>

				<ShowMore showText="Tell me more »" gaEventLabel="About">
					<p>Cybergrounds music can be described as a&nbsp;cheerful and careless soundtrack to a&nbsp;post-apocalyptic
					movie. The band itself describes it as "imagine Idiotape playing on Aphex Twin's funeral".</p>

					<p>The project started in 2012, when sax player Filip and bass guitarist Ondra decided to exploit electronic
					instruments for their musical experiments. Over the following months, they put together eponymous demo EP,
					combining electronic textures with physical instruments. After a&nbsp;quite encouraging reception, the band started
					to perform live and released a second single DAZ.</p>

					<p>In 2019 the band finally finds itself a drummer, Barney. Live drum kit notably improves the overall sound
					and the band records the first LP "Salt".</p>

					<p>In 2023 Cybergrounds release EP named "Bound for hell to save the princess and kill the baddies", produced by Dan Friml.
					</p>

					<p>The band initially lacked a vocalist; since Cybergrounds generally dislike human singers, they built
					themselves Otylia, a&nbsp;robot with 3D-modeled animated face, who accompanies them on&nbsp;concerts.
					Otylia is well mannered and rarely attacks humans. She has a demo video{' '}
					<OutboundLink href="https://youtu.be/v9PFHjAfc9o" newTab={true}>on&nbsp;YouTube</OutboundLink>.
					</p>
				</ShowMore>
			</>;

			default: return '';
		}
	}

	showMore_(event) {
		event.preventDefault();
		this.setState({ more: true, });
	}
}
About.contextType = LanguageContext;
