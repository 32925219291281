import React, { Component } from 'react';
import LanguageContext, { TRANSLATIONS } from './LanguageContext';
import OutboundLink from './OutboundLink';
import './Contact.scss';
import photo1 from '../assets/cybergrounds1.jpg';
import photo2 from '../assets/cybergrounds2.jpg';

export default class Contact extends Component {
	render() {
		const fbLink = 'www.facebook.com/cybergroundscz/';
		const igLink = 'www.instagram.com/cybergrounds/';
		const language = this.context.language;

		return <>
			<p>Mail: <a href="mailto:info@cybergrounds.cz">info@cybergrounds.cz</a></p>
			<p>{TRANSLATIONS.phoneAbbrev[language]}: <a href="tel:+420602348949">+420&nbsp;602&nbsp;348&nbsp;949</a> (Ondra),{' '}
			<a href="tel:+420776270421">+420&nbsp;776&nbsp;270&nbsp;421</a> (Barney)</p>
			<p>FB: <OutboundLink href={`https://${fbLink}`} gaLabel="FB">{fbLink}</OutboundLink></p>
			<p>IG: <OutboundLink href={`https://${igLink}`} gaLabel="instagram">{igLink}</OutboundLink></p>
			<div className="contact-photos">
				<img src={photo1}/>
				<img src={photo2}/>
			</div>
		</>;
	}
}
Contact.contextType = LanguageContext;
