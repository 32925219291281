import React, { Component } from 'react';
import LanguageContext, { TRANSLATIONS } from './LanguageContext';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import './Thumbnails.scss';

export default class Thumbnails extends Component {
	constructor(props) {
		super(props);
		this.containerRef_ = React.createRef();
		this.thumbnailRef_ = this.thumbnailRef_.bind(this);
		this.thumbnails_ = [];
		this.thumbnailVisibility_ = [];
		this.state = {
			firstHidden: false,
			lastHidden: false,
			plainScroll: false,
		}
		this.observers_ = [];
		this.scrollLeft_ = this.scrollLeft_.bind(this);
		this.scrollRight_ = this.scrollRight_.bind(this);
	}

	componentDidMount() {
		if (typeof IntersectionObserver === 'function' && typeof this.containerRef_.current.scrollBy === 'function') {
			[
				[ 0, 'firstHidden' ],
				[ this.thumbnails_.length - 1, 'lastHidden' ],
			].forEach(elem => {
				const observer = new IntersectionObserver(
					entries => this.updateHidden_(elem[1], entries),
					{
						root: this.containerRef_.current,
						rootMargin: '10px 0px', // make space for Android scrollbar
						threshold: 1,
					}
				);
				observer.observe(this.thumbnails_[elem[0]]);
				this.observers_.push(observer);
			});
		}
	}

	componentWillUnmount() {
		this.observers_.forEach(observer => observer.disconnect());
	}

	render() {
		return <div className='thumbnails'>
			<button
				className={'thumbnail-scroller thumbnail-scroller-left unstyled-button' + (this.state.firstHidden ? '' : ' thumbnail-scroller-hidden')}
				onClick={this.scrollLeft_}
				title={TRANSLATIONS.previousPhotos[this.context.language]}
			>
				<Icon type='scroller' />
			</button>
			<div className="thumbnails-scrollarea" ref={this.containerRef_} onScroll={this.updateScrollers_}>
				<ThumbnailImages {...this.props} thumbnailRef={this.thumbnailRef_}/>
			</div>
			<button
				className={'thumbnail-scroller thumbnail-scroller-right unstyled-button' + (this.state.lastHidden ? '' : ' thumbnail-scroller-hidden')}
				onClick={this.scrollRight_}
				title={TRANSLATIONS.nextPhotos[this.context.language]}
			>
				<Icon type='scroller' />
			</button>
		</div>;
	}

	thumbnailRef_(element, index, count) {
		this.thumbnails_[index] = element;
	}

	updateHidden_(stateKey, entries) {
		if (entries.length === 0) {
			return; // should not happen
		}
		const ratio = entries[0].intersectionRatio;
		const state = {};
		state[stateKey] = ratio < 1;
		this.setState(state);
	}

	scrollLeft_() {
		this.containerRef_.current.scrollBy(-150, 0);
	}

	scrollRight_() {
		this.containerRef_.current.scrollBy(150, 0);
	}
}

class ThumbnailImages extends React.PureComponent {
	render() {
		const keys = this.props.keys;
		const images = keys.map((key, index) => {
			const thumbnail = this.props.thumbnails(key);

			return <Link
				to={`${index}.html`}
				key={key}
				className="thumbnail"
				ref={el => this.props.thumbnailRef(el, index, keys.length)}
			>
				<img src={thumbnail.src}/>
			</Link>;
		});
		return <>{images}</>;
	}
}
Thumbnails.contextType = LanguageContext;
