import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Switch, Route, Link } from 'react-router-dom';
import './Photos.scss';
import PhotoPopUp from './PhotoPopUp';
import { gallery, photos, thumbnails, collectionByDir, photosForCollection } from '../util/photos';

class Photos extends Component {
	render() {
		const collectionsList = gallery.collections.map(collection => {
			const keys = photosForCollection(collection);
			const cards = keys.slice(0, 3).map(key => <img key={key} className="photos-card" src={thumbnails(key)}/>);

			const target = `${this.props.match.url}photo/${collection.dir}/0.html`;
			return <Link to={target} key={collection.dir} className="photos-collection">
				<div className="photos-collectionDescription">{collection.description}</div>
				<div className="photos-cards">{cards}</div>
			</Link>
		});

		const parentPath = this.props.match.path === '/' ? '' : this.props.match.path;
		return <>
			<div className="photos-collections">
				{collectionsList}
			</div>

			<Switch>
				<Route path={`${parentPath}/photo/:collection/:index`} render={(routeProps) => {
					const collection = collectionByDir(routeProps.match.params.collection);
					return <PhotoPopUp
						photos={photos}
						keys={photosForCollection(collection)}
						thumbnails={thumbnails}
						collection={collection}
						index={routeProps.match.params.index}
					/>
				}}/>
			</Switch>
		</>;
	}
}

export default withRouter(Photos);
