import React, { Component } from 'react';
import Icon from './Icon';
import { playerService } from '../service/playerService';
import { splitTitle } from '../util/songs';
import './Player.scss';

export default class Player extends Component {
	constructor(props) {
		super(props);
		this.state = {
			shown: false,
			autoplay: false,
			song: playerService.selectedSong,
			songIndex: playerService.selectedSongIndex,
			...playerService.playState,
		};

		this.audioRef_ = React.createRef();
		this.playheadRef_ = React.createRef();
		this.seekerRef_ = React.createRef();
		this.currentTimeRef_ = React.createRef();
		this.durationRef_ = React.createRef();

		this.togglePlay_ = this.togglePlay_.bind(this);
		this.rewind_ = this.rewind_.bind(this);
		this.forward_ = this.forward_.bind(this);
		this.close_ = this.close_.bind(this);
		this.onPlayState_ = this.onPlayState_.bind(this);
		this.onPlayRequested_ = this.onPlayRequested_.bind(this);
		this.onPosition_ = this.onPosition_.bind(this);
		this.onSongSelected_ = this.onSongSelected_.bind(this);
		this.onWaveformMouseMove_ = this.onWaveformMouseMove_.bind(this);
		this.onWaveformMouseLeave_ = this.onWaveformMouseLeave_.bind(this);
		this.onWaveformClick_ = this.onWaveformClick_.bind(this);
	}

	componentDidMount() {
		playerService.playStateChanges.addListener(this.onPlayState_);
		playerService.playRequested.addListener(this.onPlayRequested_)
		playerService.position.addListener(this.onPosition_);
		playerService.songSelection.addListener(this.onSongSelected_);
		playerService.setAudioElement(this.audioRef_.current);
	}

	componentWillUnmount() {
		playerService.playStateChanges.removeListener(this.onPlayState_);
		playerService.playRequested.removeListener(this.onPlayRequested_)
		playerService.position.removeListener(this.onPosition_);
		playerService.songSelection.removeListener(this.onSongSelected_);
	}

	render() {
		const song = this.state.song;
		const titles = splitTitle(song);
		const className = [
			'player',
			this.state.paused ? 'player-paused' : '',
			this.state.buffering ? 'player-buffering' : '',
			this.state.ended ? 'player-ended' : '',
		].join(' ');
		const download = this.download_.bind(this, this.state.songIndex);

		return <>
			<audio
				src={song.file}
				preload="auto"
				autoPlay={this.state.autoplay}
				ref={this.audioRef_}
			/>
			<div className={className} style={this.state.shown ? {} : { maxHeight: 0, paddingTop: 0, paddingBottom: 0 }}>
				<div className="player-waveformWrapper">
					<div
						className="player-waveform"
						onMouseMove={this.onWaveformMouseMove_}
						onMouseLeave={this.onWaveformMouseLeave_}
						onClick={this.onWaveformClick_}
					>
						<img src={song.imgFile}/>
						<div className="player-playhead" ref={this.playheadRef_}/>
						<div className="player-seeker" ref={this.seekerRef_}/>
						<div className="player-currentTime" ref={this.currentTimeRef_}/>
						<div className="player-duration" ref={this.durationRef_}/>
					</div>
				</div>
				<div className="player-controls">
					<div className="player-controlsTopRow">
						<div className="player-songInfo">
							<div className="player-title">{titles.title}</div>
							{titles.subtitle && <div className="player-subtitle">{titles.subtitle}</div>}
							<div className="player-albumAndTrackNumber">{song.tags.album} - {song.tags.trackNumber}</div>
						</div>

						<button className="unstyled-button player-closeButton" onClick={this.close_}>
							<Icon type="close"/>
						</button>
					</div>

					<div className="player-icons">
						<button className="unstyled-button" onClick={this.rewind_}>
							<Icon type="rewind"/>
						</button>
						<button className="unstyled-button" onClick={this.togglePlay_}>
							<Icon type="play"/>
							<Icon type="pause"/>
						</button>
						<a download href={song.file} onClick={download}>
							<Icon type="download"/>
						</a>
						<button className="unstyled-button" onClick={this.forward_}>
							<Icon type="forward"/>
						</button>
					</div>
				</div>
			</div>
		</>;
	}

	togglePlay_() {
		playerService.togglePlay();
	}

	rewind_() {
		playerService.rewind();
	}

	forward_() {
		playerService.forward();
	}

	close_() {
		playerService.pause();
		this.setState({ shown: false });
	}

	onPlayState_(state) {
		const ended = !this.state.ended && state.ended;
		this.setState(state);
		if (ended) {
			playerService.forward(true);
		}
	}

	onPlayRequested_() {
		this.setState({ shown: true, autoplay: true });
	}

	onPosition_(event) {
		if (event.duration === 0) return;
		this.playheadRef_.current.style = 'left: ' + (100 * event.currentTime / event.duration) + '%';
		this.updateTimeElement_(this.durationRef_.current, event.duration);
		this.updateTimeElement_(this.currentTimeRef_.current, event.currentTime);
	}

	onSongSelected_(event) {
		this.setState({
			song: event.song,
			songIndex: event.index,
		});
	}

	onWaveformMouseMove_(event) {
		this.seekerRef_.current.style = 'display: block; left: ' + 100 * this.computeMouseXRatio_(event) + '%;';
	}

	onWaveformMouseLeave_() {
		this.seekerRef_.current.style = '';
	}

	onWaveformClick_(event) {
		playerService.seekProportional(this.computeMouseXRatio_(event));
		if (playerService.playState.paused) {
			playerService.play();
		}
	}

	computeMouseXRatio_(event) {
		const rect = event.target.getBoundingClientRect();
		if (rect.width === 0) {
			return 0;
		} else {
			return (event.clientX - rect.left) / rect.width;
		}
	}

	updateTimeElement_(element, time) {
		const html = this.formatTime_(time);
		if (element.innerHTML != html) {
			element.innerHTML = html;
		}
	}

	formatTime_(time) {
		time = Math.floor(time);
		const seconds = time % 60;
		const minutes = (time - seconds) / 60;
		return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
	}

	download_(index) {
		console.log('dl', index);
		ga('send', 'event', 'Music', 'Download', 'Salt', index);
	}
}
