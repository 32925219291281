import React, { Component } from 'react';

export const ScrollRestorerContext = React.createContext();

class ScrollRestorer extends Component {
	constructor(props) {
		super(props);
		this.scrollableRef_ = React.createRef();
		this.positions_ = {};
	}

	componentDidMount() {
		this.unlistenHistory_ = this.props.history.listen(this.onHistoryAction_.bind(this));
		this.locationKey_ = this.props.history.location.key;
		this.storePosition_();
	}

	componentWillUnmount() {
		this.unlistenHistory_();
	}

	onHistoryAction_(location, action) {
		const scrollable = this.scrollableRef_.current;
		if (!scrollable) {
			return;
		}

		if (action === 'POP') {
			const previous = this.positions_[location.key];
			if (previous) {
				scrollable.scrollTo(previous.left, previous.top);
			}
		} else {
			this.storePosition_();
		}
		this.locationKey_ = location.key;
	}

	storePosition_() {
		const scrollable = this.scrollableRef_.current;
		if (!scrollable) {
			return;
		}

		const position = {
			left: scrollable.scrollLeft,
			top: scrollable.scrollTop,
		};
		this.positions_[this.locationKey_] = position;
	}

	render() {
		return React.cloneElement(React.Children.only(this.props.children), { scrollableRef: this.scrollableRef_ });
	}
}

export default function restoreScroll(Component) {
	return (props) => {
		return <ScrollRestorerContext.Consumer>
			{context => <ScrollRestorer history={context}><Component {...props}/></ScrollRestorer>}
		</ScrollRestorerContext.Consumer>;
	};
}
