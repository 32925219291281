import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LanguageContext, { LANGUAGES } from './LanguageContext';

import './LanguageSwitcher.scss';

class LanguageSwitcher extends Component {
  render() {
    const links = LANGUAGES.map(language => {
      const active = language.code === this.context.language;
      const target = language.isDefault ? '/' : ('/' + language.code + '/');
      return <Link
        key={language.code}
        to={target}
        title={language.label}
        className={'language-switcher-link ' + (active ? 'language-switcher-active' : '')}
        onClick={() => this.updateHtmlLang(language)}
      >
        {language.code.toUpperCase()}
      </Link>;
    });
    return <div className="language-switcher">
      {links}
    </div>;
  }

  updateHtmlLang(language) {
    document.documentElement.lang = language.code;
  }
}
LanguageSwitcher.contextType = LanguageContext;

export default LanguageSwitcher;
