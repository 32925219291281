import React, { Component } from 'react';
import gaOutbound from '../util/gaOutbound';

export default class OutboundLink extends Component {
	render() {
		const { href, gaLabel, newTab, download, children, ...restProps } = this.props;

		return <a
			href={href}
			onClick={gaOutbound.bind(null, href, gaLabel, newTab)}
			download={download === undefined ? null : download}
			{...restProps}
		>{children}</a>;
	}
}
