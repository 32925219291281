import React from 'react';

export const LANGUAGES = [
  {
    code: 'cs',
    label: 'Česky',
    isDefault: true, // ALWAYS keep default language first.
  },
  {
    code: 'en',
    label: 'English',
  },
];

const LanguageContext = React.createContext(autodetectContext());
export default LanguageContext;

export function autodetectContext() {
  const language = autodetectLanguage();
  return {
    language,
  };
}

export function autodetectLanguage() {
  return 'cs'; // for now
}

export function sectionTitle(section, language) {
  return TRANSLATIONS[section.id + 'Section'][language];
}

export function translateDate(date, language) {
  let day;
  if (language === 'cs') {
    day = date.getUTCDate() + '.';
  } else {
    day = date.getUTCDate();
  }
  const month = TRANSLATIONS['month' + (date.getUTCMonth() + 1)][language];
  const year = date.getUTCFullYear();
  return {
    day,
    month,
    year,
  };
}

export const TRANSLATIONS = {
  aboutSection: {
    cs: 'O kapele',
    en: 'About',
  },
  concertsSection: {
    cs: 'Koncerty',
    en: 'Concerts',
  },
  pressSection: {
    cs: 'Napsali o nás',
    en: 'Press',
  },
  mediaSection: {
    cs: 'Hudba & Video',
    en: 'Music & Video',
  },
  photosSection: {
    cs: 'Fotky',
    en: 'Photos',
  },
  promoSection: {
    cs: 'Pro pořadatele',
    en: 'Promo',
  },
  contactSection: {
    cs: 'Kontakt',
    en: 'Contact',
  },
  showMoreConcerts: {
		cs: 'Ukázat uplynulé koncerty',
		en: 'Show past concerts',
	},
	hideMoreConcerts: {
		cs: 'Skrýt uplynulé koncerty',
		en: 'Hide past concerts',
	},
  phoneAbbrev: {
    cs: 'Tel',
    en: 'Phone',
  },
  month1: {
    cs: 'ledna',
    en: 'january',
  },
  month2: {
    cs: 'února',
    en: 'february',
  },
  month3: {
    cs: 'března',
    en: 'march',
  },
  month4: {
    cs: 'dubna',
    en: 'april',
  },
  month5: {
    cs: 'května',
    en: 'may',
  },
  month6: {
    cs: 'června',
    en: 'jun',
  },
  month7: {
    cs: 'července',
    en: 'jul',
  },
  month8: {
    cs: 'srpna',
    en: 'august',
  },
  month9: {
    cs: 'září',
    en: 'september',
  },
  month10: {
    cs: 'října',
    en: 'october',
  },
  month11: {
    cs: 'listopadu',
    en: 'november',
  },
  month12: {
    cs: 'prosince',
    en: 'december',
  },
  cookieInfo: {
    cs: 'Používáme cookies nutné pro provoz webu a&nbsp;analytiku.',
    en: 'We use cookies required for operation and for analytics.',
  },
  ok: {
    cs: 'OK',
    en: 'OK',
  },
  closeGallery: {
    cs: 'Zavřít galerii',
    en: 'Close the gallery,'
  },
  previousPhoto: {
    cs: 'Předchozí obrázek',
    en: 'Previous photo',
  },
  previousPhotos: {
    cs: 'Předchozí obrázky',
    en: 'Previous photos',
  },
  nextPhoto: {
    cs: 'Další obrázek',
    en: 'Next photo',
  },
  nextPhotos: {
    cs: 'Další obrázky',
    en: 'Next photos',
  },
};
