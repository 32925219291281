import React, { Component } from 'react';
import Icon from './Icon';
import { albumSongs, splitTitle, parseTrackNumber } from '../util/songs';
import { playerService } from '../service/playerService';
import './BoundForHell.scss';

const boundForHellSongs = albumSongs('Bound for Hell to save the princess and kill the baddies');
import boundForHellCover from '../assets/boundForHell-cover.jpg?size=600';
import saltBackcover from '../assets/salt-backcover.svg';

export default class BoundForHell extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDownloadLinks: false,
			paused: true,
		};
		this.showDownloadLinks_ = this.showDownloadLinks_.bind(this);
		this.hideDownloadLinks_ = this.hideDownloadLinks_.bind(this);
		this.togglePlay_ = this.togglePlay_.bind(this);
		this.onPlayState_ = this.onPlayState_.bind(this);
	}

	componentDidMount() {
		playerService.playStateChanges.addListener(this.onPlayState_);
	}

	componentWillUnmount() {
		playerService.playStateChanges.removeListener(this.onPlayState_);
	}

	render() {
		const songItems = boundForHellSongs.map((song, songIndex) => {
			const titles = splitTitle(song);
			const trackNumber = parseTrackNumber(song);
			const className = [
				'unstyled-button',
				'boundForHell-track',
				(trackNumber.trackNumber > trackNumber.total) ? 'boundForHell-bonusTrack' : 'boundForHell-regularTrack',
				(trackNumber.trackNumber === 1) ? 'boundForHell-firstRegularTrack' : '',
				(trackNumber.trackNumber === trackNumber.total) ? 'boundForHell-lastRegularTrack' : '',
			].join(' ');

			return <button key={song.name} className={className} onClick={() => this.selectSong_(songIndex)}>
				<span className="boundForHell-songTitle">{titles.title}</span>
				{ titles.subtitle && <span className="boundForHell-songSubtitle">{titles.subtitle}</span>}
			</button>;
		});

		const downloadLinks = boundForHellSongs.map((song, index) => {
			const onClick = this.downloadSong_.bind(this, index);
			const shortName = song.name.replace('-cybergrounds', '');
			return <a key={song.name} download href={song.file} onClick={onClick}>{shortName}</a>;
		});

		return <div className="boundForHell-wrapper">
			<div className={'boundForHell' + (this.state.paused ? '' : ' boundForHell-playing')}>
				<img src={boundForHellCover.src}/>
				<div className="boundForHell-content">
					<div className="salt-backCover" dangerouslySetInnerHTML={{__html: saltBackcover }}/>
					<div className="boundForHell-trackList">
						{songItems}
					</div>
					<button className="boundForHell-play unstyled-button" onClick={this.togglePlay_}>
						<Icon type="play" />
						<Icon type="pause" />
					</button>
					<button
						className="boundForHell-download unstyled-button"
						onFocus={this.showDownloadLinks_}
						onBlur={this.hideDownloadLinks_}
					>
						<Icon type="download" />
						{this.state.showDownloadLinks && 
							<div className="boundForHell-downloadLinks">{downloadLinks}</div>
						}
					</button>
				</div>
			</div>
		</div>;
	}

	showDownloadLinks_() {
		this.setState({ showDownloadLinks: true });
	}

	hideDownloadLinks_() {
		setTimeout(() => {
			this.setState({ showDownloadLinks: false });
		}, 200);
	}

	togglePlay_() {
		playerService.togglePlay();
	}

	onPlayState_(state) {
		this.setState({ paused: state.paused });
	}

	selectSong_(index) {
		playerService.selectSong(index, true);
		playerService.play();
	}

	downloadSong_(index) {
		console.log('dl', index);
		ga('send', 'event', 'Music', 'Download', 'boundForHell', index);
	}
}
