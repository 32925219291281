export default function gaOutbound (url, label, newTab, event) {
	ga('send', 'event', 'Outbound', (label ? label : 'click'), url, {
		'transport': 'beacon',
		'hitCallback': function() {
			if (newTab) {
				window.open(url, '_blank');
			} else {
				document.location = url;
			}
		}
	});
	event.preventDefault();
}
