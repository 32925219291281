export const songs = require.context('../assets/songs', true, /\.mp3$/i);
export const sortedSongs = songs.keys().map(key => songs(key)).sort(compareTrackNumber);

export function albumSongs(album) {
	return sortedSongs.filter(song => song.tags.album === album);
}

export function compareTrackNumber(a, b) {
	return retrieveTrackNumber(a) - retrieveTrackNumber(b);
}

export function retrieveTrackNumber(song) {
	return parseTrackNumber(song).trackNumber;
}

export function parseTrackNumber(song) {
	const match = song.tags.trackNumber.match(/^(\d+)\/(\d+)$/);
	if (match) {
		return {
			trackNumber: parseInt(match[1]),
			total: parseInt(match[2]),
		};
	} else {
		return {
			trackNumber: 1,
			total: 1,
		};
	}
}

export function splitTitle(song) {
	const match = song.tags.title.match(/^(.*) (\(.*\))$/);
	if (match) {
		return {
			title: match[1],
			subtitle: match[2],
		}
	} else {
		return {
			title: song.tags.title,
		}
	}
}
