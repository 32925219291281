import EventSource from '../util/eventSource';
import { sortedSongs } from '../util/songs';

export class PlayerService {
	constructor() {
		this.playStateChanges = new EventSource();
		this.playRequested = new EventSource();
		this.songSelection = new EventSource();
		this.position = new EventSource();

		this.selectSong(0);
		this.playState = {
			buffering: true,
			paused: true,
			ended: false,
		};
	}

	setAudioElement(el) {
		this.audioElement = el;
		el.addEventListener('play', () => this.onPlay_());
		el.addEventListener('pause', () => this.onPause_());
		el.addEventListener('waiting', () => this.onWaiting_());
		el.addEventListener('playing', () => this.onPlaying_());
		el.addEventListener('seeking', () => this.onSeeking_());
		el.addEventListener('loadeddata', () => this.firePlayState_());
		el.addEventListener('ended', () => this.onEnded_());
		el.addEventListener('timeupdate', () => this.onTimeUpdate_());
	}

	selectSong(index, byUser) {
		if (this.selectedSongIndex !== undefined) {
			this.stopSongMeasurement_();
		}

		this.selectedSongIndex = index;
		this.selectedSong = sortedSongs[index];
		this.songPlayingFrom_ = undefined;
		this.songPlayedTotal_ = 0;
		this.songSelection.fire({
			index,
			song: this.selectedSong,
		});

		if (byUser) {
			this.gaEvent('SelectSong');
		}
	}

	play() {
		if (this.audioElement) {
			this.audioElement.play();
			this.gaEvent('Play');
		}
		this.playRequested.fire();
	}

	pause() {
		if (this.audioElement) {
			this.audioElement.pause();
			this.gaEvent('Pause');
		}
	}

	togglePlay() {
		if (this.playState.paused) {
			this.play();
		} else {
			this.pause();
		}
	}

	seekProportional(ratio) {
		if (this.audioElement) {
			this.audioElement.currentTime = this.audioElement.duration * ratio;
			this.gaEvent('Seek');
		}
	}

	rewind() {
		if (this.audioElement) {
			if (this.audioElement.currentTime > 5) {
				this.audioElement.currentTime = 0;
				this.gaEvent('Rewind');
			} else if (this.selectedSongIndex === 0) {
				this.selectSong(sortedSongs.length - 1);
				this.gaEvent('Previous');
			} else {
				this.selectSong(this.selectedSongIndex - 1);
				this.gaEvent('Previous');
			}
		}
	}

	forward(auto) {
		if (this.audioElement) {
			if (this.selectedSongIndex < sortedSongs.length - 1) {
				this.selectSong(this.selectedSongIndex + 1);
			} else {
				this.selectSong(0);
			}
			this.gaEvent(auto ? 'AutoNext' : 'Next');
		}
	}

	firePlayState_() {
		this.playState = {
			buffering: this.audioElement.readyState <= 2,
			paused: this.audioElement.paused,
			ended: this.audioElement.ended,
		};
		this.playStateChanges.fire(this.playState);
	}

	onPlay_() {
		this.firePlayState_();
	}

	onPause_() {
		this.accumulatePlayTime_();
		this.firePlayState_();
	}

	onPlaying_() {
		this.songPlayingFrom_ = this.audioElement.currentTime;
	}

	onSeeking_() {
		this.accumulatePlayTime_();
	}

	onWaiting_() {
		this.accumulatePlayTime_();
		this.firePlayState_();
	}

	onEnded_() {
		this.stopSongMeasurement_();
		this.firePlayState_();
	}

	onTimeUpdate_(element) {
		this.lastKnownPosition_ = this.audioElement.currentTime;
		this.position.fire({
			currentTime: this.audioElement.currentTime,
			duration: this.audioElement.duration,
		});
	}

	accumulatePlayTime_() {
		if (this.songPlayingFrom_ !== undefined && this.lastKnownPosition_ !== undefined) {
			const played = this.lastKnownPosition_ - this.songPlayingFrom_;
			this.songPlayedTotal_ += played;
		}
		this.songPlayingFrom_ = undefined;
		this.lastKnownPosition_ = undefined;
	}

	stopSongMeasurement_() {
		this.accumulatePlayTime_();
		if (this.audioElement.duration > 0) {
			const playedRatio = this.songPlayedTotal_ / this.audioElement.duration;
			if (playedRatio > .95) {
				this.gaEvent('Complete');
			}
		}
	}

	gaEvent(action) {
		if (ga !== 'undefined') {
			ga('send', 'event', 'Music', action, 'Salt', this.selectedSongIndex);
		}
	}
}

export const playerService = new PlayerService();
