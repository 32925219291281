import React, { Component } from 'react';
import LanguageContext, { translateDate } from './LanguageContext';
import './FancyDate.scss';

export default class FancyDate extends Component {
	render() {
		const date = translateDate(this.props.date, this.context.language);
		return <div className={'fancyDate ' + (this.props.fancy ? 'fancyDate-realFancy' : 'fancyDate-notThatFancy')}>
			<span className="fancyDate-day">{date.day}</span>
			<span className="fancyDate-month">{date.month}</span>
			<span className="fancyDate-year">{date.year}</span>
		</div>;
	}
}
FancyDate.contextType = LanguageContext;
