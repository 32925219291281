import React, { Component } from 'react';
import LanguageContext, { TRANSLATIONS } from './LanguageContext';
import Concert from './Concert';
import ShowMore from './ShowMore';
import './Concerts.scss';
import concerts from '../assets/concerts.json';

export default class Concerts extends Component {
	constructor(props) {
		super(props);

		concerts.concerts.forEach(concert => {
			concert.date = new Date(concert.date);
		});
	}

	render() {
		const dateLimit = (new Date()).getTime() - 12 * 60 * 60 * 1000;
		const collections = concerts.concerts.reduce((collections, concert) => {
			const target = (concert.date.getTime() < dateLimit) ? collections.past : collections.future;
			target.push(concert);
			return collections;
		}, {
			future: [],
			past: [],
		});

		const pastConcerts = collections.past.reverse().map((concert, index) => {
			return <Concert key={index} data={concert} past={true}/>
		});

		const futureConcerts = collections.future.map((concert, index) => {
			return <Concert key={index} data={concert} past={false}/>
		});

		return <>
			{futureConcerts}
			<ShowMore
				showText={TRANSLATIONS.showMoreConcerts[this.context.language] + '»'}
				hideText={TRANSLATIONS.hideMoreConcerts[this.context.language] + '»'}
				className="concerts-past"
				gaEventLabel="Concerts"
			>
				{pastConcerts}
			</ShowMore>
		</>;
	}
}
Concerts.contextType = LanguageContext;
