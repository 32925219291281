import React, { Component } from 'react';
import './ShowMore.scss';

export default class ShowMore extends Component {
	constructor(props) {
		super(props);
		this.state = {
			shown: false,
			onceShown: false,
		};
		this.toggleVisible_ = this.toggleVisible_.bind(this);
	}

	render() {
		const toggleLinkText = this.state.shown ? this.props.hideText : this.props.showText;
		const toggleLink = (toggleLinkText !== undefined)
			? <a href="#" onClick={this.toggleVisible_} className="showMore-link">{toggleLinkText}</a>
			: null;

		const baseClassName = 'showMore' + (this.props.className ? (' ' + this.props.className) : '');
		const visibleClassName = baseClassName + ' showMore-visible';

		return <>
			{toggleLink}
			<div className={this.state.shown ? visibleClassName : baseClassName}>
				{this.state.onceShown && this.props.children}
			</div>
			<noscript>
				<div className={visibleClassName}>
					{this.props.children}
				</div>
			</noscript>
		</>
	}

	toggleVisible_(event) {
		const shown = !this.state.shown;
		this.setState({
			shown,
			onceShown: this.state.onceShown || shown
		});
		if (shown && this.props.gaEventLabel) {
			ga('send', 'event', 'ShowMore', this.props.gaEventLabel);
		}
		event.preventDefault();
	}
}
