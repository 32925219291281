import React, { Component} from "react";
import restoreScroll from './ScrollRestorer';
import LanguageContext, { sectionTitle } from './LanguageContext';
import Header from './Header';
import About from './About';
import Concerts from './Concerts';
import Press from './Press';
import Media from './Media';
import Photos from './Photos';
import Promo from './Promo';
import Contact from './Contact';
import './AppContents.scss';

class AppContents extends Component {
	render() {
		const sections = [
			{
				id: 'about',
				component: About,
			},
			{
				id: 'concerts',
				component: Concerts,
			},
			{
				id: 'press',
				component: Press,
			},
			{
				id: 'media',
				component: Media,
			},
			{
				id: 'photos',
				component: Photos,
			},
			{
				id: 'promo',
				component: Promo,
			},
			{
				id: 'contact',
				component: Contact,
			},
		];
		const sectionElements = sections.map((section, index) => {
			const Heading = index > 0 ? 'h2' : 'h1';
			return <React.Fragment key={section.id}>
				<div className="linkTarget" id={section.id}/>
				<section>
					<Heading>{sectionTitle(section, this.context.language)}</Heading>
					<section.component section={section}/>
				</section>
			</React.Fragment>
		});

		return(
			<div className="AppContents-wrapper">
				<div className="AppContents" ref={this.props.scrollableRef}>
					<Header sections={sections} getAppContentsScrollable={() => this.props.scrollableRef.current}/>

					{sectionElements}
				</div>
			</div>
		);
	}
}
AppContents.contextType = LanguageContext;

export default restoreScroll(AppContents);
