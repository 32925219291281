import React, { Component } from 'react';
import FancyDate from './FancyDate';
import OutboundLink from './OutboundLink';
import './Concert.scss';

export default class Concert extends Component {
	render() {
		const supportLinks = this.artistList_(this.props.data.support);
		const lineupLinks = this.artistList_(this.props.data.lineup);
		const tags = this.props.data.tags
			? this.props.data.tags.map((tag, index) => <span className='concert-tag' key={index}>{tag}</span>)
			: null;

		return <div className={'concert' + (this.props.past ? ' concert-past' : '')}>
			<FancyDate date={this.props.data.date} fancy={!this.props.past}/>
			<div className="concert-name">{this.props.data.name}</div>
			{ tags && <div className="concert-tags">{tags}</div> }
			<div className="concert-where">
				<span className="concert-place">{this.props.data.place}</span>
				{this.props.data.city &&
					<>
						{', '}
						<span className="concert-city">{this.props.data.city}</span>
					</>
				}
			</div>
			{supportLinks.length > 0 &&
				<div className="concert-artists">
					support: {supportLinks}
				</div>
			}
			{lineupLinks.length > 0 &&
				<div className="concert-artists">
					lineup: {lineupLinks}
				</div>
			}
		</div>;
	}

	artistList_(artists) {
		return (artists || []).map((band, index) => {
			const link = band.href
				? <OutboundLink
					key={band.name}
					href={band.href}
					gaLabel="Artist"
				>{band.name}</OutboundLink>
				: <span>{band.name}</span>;

			return <React.Fragment key={index}>
				{(index > 0 && <span>, </span>)}
				{link}
			</React.Fragment>
		});
	}
}
