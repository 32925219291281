import React, { Component } from 'react';
import LanguageContext from './LanguageContext';
import OutboundLink from './OutboundLink';

const promoUrl = {
	cs: 'https://promo.cybergrounds.cz',
	en: 'https://promo.cybergrounds.cz/index-en.html',
};
const presskitUrl = {
	cs: 'https://promo.cybergrounds.cz/assets/cybergrounds-promotext-cs.pdf',
	en: 'https://promo.cybergrounds.cz/assets/cybergrounds-promotext-en.pdf',
};

export default class Promo extends Component {
	render() {
		switch(this.context.language) {
			case 'cs':
				return <>
					<p>Základní informace jsou{' '}
					<a href={presskitUrl.cs} download onClick={() => this.downloadPresskit_()}>v promo dokumentu</a>.</p>
					<p>Vše ostatní, jako stageplan, grafiku ve vysokém rozlišení apod. naleznete na zvláštní stránce{' '}
					<OutboundLink href={promoUrl.cs} gaLabel="Promo">{promoUrl.cs}</OutboundLink>
					</p>
				</>;

			case 'en':
				return <>
					<p>Basic information can be found in{' '}
					<a href={presskitUrl.en} download onClick={() => this.downloadPresskit_()}>the promo document</a>.</p>
					<p>Everything else (stage plan, high resolution graphics etc.) can be found on a dedicated page{' '}
					{' '}<OutboundLink href={promoUrl.en} gaLabel="Promo">{promoUrl.en}</OutboundLink>
					</p>
				</>;

			default: return '';
		}
	}

	downloadPresskit_() {
		ga('send', 'event', 'Outbound', 'Promo', 'presskit');
		return true;
	}
}
Promo.contextType = LanguageContext;
