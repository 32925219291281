import React, { Component} from "react";
import { Switch, Route } from 'react-router-dom';
import Footer from './Footer';
import Background from './Background';
import AppContents from './AppContents';
import LanguageContext, { LANGUAGES, autodetectContext } from './LanguageContext';

import './App.scss';

class App extends Component {
	render() {
		const routes = [];
		LANGUAGES.forEach(language => {
			const context = { language: language.code };
			const path = language.isDefault ? '/' : `/${language.code}`;
			const key = language.code;
			routes.push(<Route path={path} key={key}>
				<LanguageContext.Provider value={context}>
					<div className="App">
						<Background/>

						<AppContents/>

						<Footer/>
					</div>
				</LanguageContext.Provider>
			</Route>);
		});
		routes.reverse(); // default language should be first, reverse route order

		return(
			<Switch>
				{routes}
			</Switch>
		);
	}
}

export default App;
