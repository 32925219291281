import React, { Component} from "react";
import LanguageContext, { TRANSLATIONS } from './LanguageContext';
import Player from './Player';
import './Footer.scss';

export default class Footer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			cookieConsent: this.hasCookieConsent_(),
		}

		this.cookieConsentClicked_ = this.cookieConsentClicked_.bind(this);
	}

	render() {
		return (
			<div className="footer">
				<Player/>

				{!this.state.cookieConsent &&
					<div className="cookieConsent">
						<span dangerouslySetInnerHTML={{__html: TRANSLATIONS.cookieInfo[this.context.language]}}/>
						<button onClick={this.cookieConsentClicked_}>{TRANSLATIONS.ok[this.context.language]}</button>
					</div>
				}
			</div>
		);
	}

	hasCookieConsent_() {
		if (typeof document !== 'undefined' && document.cookie !== undefined) {
			return document.cookie.replace(/(?:(?:^|.*;\s*)cybergroundsCookieConsent\s*\=\s*([^;]*).*$)|^.*$/, "$1") === "true";
		} else {
			// static render
			return true;
		}
	}

	cookieConsentClicked_() {
		document.cookie = "cybergroundsCookieConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
		this.setState({ cookieConsent: true });
	}
}
Footer.contextType = LanguageContext;
