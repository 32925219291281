import React, { Component} from "react";
import { HashLink as Link } from 'react-router-hash-link';
import LanguageContext, { sectionTitle } from './LanguageContext';
import LanguageSwitcher from './LanguageSwitcher';
import './Header.scss';
import logo from '../assets/logo.svg';

export default class Header extends Component {
	constructor(props) {
		super(props);
		this.sentinelRef_ = React.createRef();
		this.state = {
			headerSticky: false,
		}
		this.observer = undefined;
	}

	componentDidMount() {
		if (typeof IntersectionObserver === 'function') {
			this.observer = new IntersectionObserver(
				this.sentinelObserved_.bind(this),
				{
					root: this.props.getAppContentsScrollable(),
					threshold: 0.01, // can't rely on isIntersecting, so we want intersectionRatio to be > 0
				}
			);
			this.observer.observe(this.sentinelRef_.current);
		} else {
			// can't determine when the header is sticky, mark it as always sticky so the logo is small
			this.setHeaderSticky_(true);
		}
	}

	componentWillUnmount() {
		if (this.observer) {
			this.observer.disconnect();
		}
	}

	render(){
		const menuItems = this.props.sections.map(section => {
			const title = sectionTitle(section, this.context.language);
			return <li key={section.id}>
				<Link
					smooth
					to={'#' + section.id}
					data-text={title}
				>{title}</Link>
			</li>
		});
		return(
			<header className="Header" className={this.state.headerSticky ? 'sticky' : ''}>
				<div className="header-sticky-sentinel" ref={this.sentinelRef_}/>
				<div className="header-logo" dangerouslySetInnerHTML={{__html: logo }}/>
				<h1 className="header-text">Cybergrounds</h1>
				<div className="header-text header-subtitle">experimental electronica</div>
				<div className="header-nav-and-language">
					<nav>
						<ul role="list">
							{menuItems}
						</ul>
					</nav>
					<LanguageSwitcher/>
				</div>
			</header>
		);
	}

	setHeaderSticky_(value) {
		this.setState({ headerSticky: value });
	}

	sentinelObserved_(entries) {
		if (entries.length) {
			this.setHeaderSticky_(entries[0].intersectionRatio <= 0);
		} else {
			// should never happen
			this.setHeaderSticky_(true);
		}
	}
}
Header.contextType = LanguageContext;
