import React, { Component } from 'react';
import LanguageContext from './LanguageContext';
import OutboundLink from './OutboundLink';
import BoundForHell from './BoundForHell';

export default class Music extends Component {
	render() {
		switch(this.context.language) {
			case 'cs':
				return <>
					<p>Naživo vypadají naše koncerty nějak takhle:</p>

					<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/7-0NHtKzwTw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

					<p>EP "Bound for hell to save the princess and kill the baddies" i starší album SALT (a občas ještě starší nahrávky) naleznete na&nbsp;službách Spotify, Google&nbsp;Music, Deezer,
					Apple&nbsp;Music a&nbsp;mnohých&nbsp;dalších.</p>

					<p>CD nosič SALT je k&nbsp;dostání na našich koncertech.</p>

					<p>Krom toho si skladby z posledního alba můžete poslechnout či stáhnout i tady u nás:</p>

					<BoundForHell/>

					<p>Skladby lze svobodně používat a&nbsp;šířit pod licencí {' '}
					<OutboundLink href="https://creativecommons.org/licenses/by-sa/4.0" gaLabel="CC">Creative Commons BY-SA</OutboundLink>.</p>
				</>;

			case 'en':
			return <>
				<p>This is how our live concerts look like:</p>

				<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/7-0NHtKzwTw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				
				<p>You can find our EP "Bound for hell to save the princess and kill the baddies" and LP "SALT" (and, sometimes, our older recordings) on Spotify, Google&nbsp;Music, Deezer,
				Apple&nbsp;Music and many others.</p>

				<p>You can get the CD on concerts.</p>

				<p>Also, you can listen to or download the last album here</p>

				<BoundForHell/>

				<p>Songs can be distributed under the following license:{' '}
				<OutboundLink href="https://creativecommons.org/licenses/by-sa/4.0" gaLabel="CC">Creative Commons BY-SA</OutboundLink>.</p>
			</>;

			default: return '';
		};
	}
}
Music.contextType = LanguageContext;
