import React, { Component } from 'react';
import LanguageContext, { TRANSLATIONS } from './LanguageContext';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import './PhotoPopUp.scss';
import Thumbnails from './Thumbnails';
import Icon from './Icon';

export class PhotoPopUp extends Component {
	constructor(props) {
		super(props);
		this.keyDown_ = this.keyDown_.bind(this);
		this.close_ = this.close_.bind(this);
	}

	componentDidMount() {
		document.addEventListener('keydown', this.keyDown_, false);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.keyDown_, false);
	}

	render() {
		const numIndex = parseInt(this.props.index);
		if (typeof ga !== 'undefined') { // disabled in SSR
			ga('send', 'event', 'Photos', 'View', this.props.collection.dir, numIndex);
		}
		const image = this.props.photos(this.props.keys[numIndex]);
		return <div className="photoPopUp">
			<div className="photoPopUp-topRow">
				<div className="photoPopUp-collectionDescription">
					<span>{this.props.collection.description}</span>
				</div>
				<a
					href="#"
					title={TRANSLATIONS.closeGallery[this.context.language]}
					className="photoPopUp-close photoPopUp-iconLink"
					onClick={this.close_}
				>
					<Icon type="close" black={true}/>
				</a>
			</div>

			<div className="photoPopUp-content">
				<div className="spinner spinner-black"/>
				<Link
					to={(numIndex ? numIndex - 1 : this.props.keys.length - 1) + '.html'}
					className="photoPopUp-iconLink photoPopUp-scrollLeft"
					title={TRANSLATIONS.previousPhoto[this.context.language]}
				>
					<Icon type="scroller" black={true}/>
				</Link>
				<div className="photoPopUp-photo" style={{ backgroundImage: 'url(' + image.src + ')' }}/>
				<Link
					to={(numIndex >= this.props.keys.length - 1 ? 0 : numIndex + 1) + '.html'}
					className="photoPopUp-iconLink photoPopUp-scrollRight"
					title={TRANSLATIONS.nextPhoto[this.context.language]}
				>
					<Icon type="scrollerRight" black={true}/>
				</Link>
			</div>

			<Thumbnails thumbnails={this.props.thumbnails} collection={this.props.collection} keys={this.props.keys} />
		</div>;
	}

	keyDown_(event) {
		if (event.key === 'Escape' || event.code === 'Escape') {
			this.close_(event);
		}
	}

	close_(event) {
		event.preventDefault();
		this.props.history.push('../..');
	}
}
PhotoPopUp.contextType = LanguageContext;

export default PhotoPopUp = withRouter(PhotoPopUp);
