import 'core-js';
import 'regenerator-runtime';

import './assets/sitemap.xml';

import React from "react";
import BrowserApp from "./components/BrowserApp.jsx";
import ReactDOM, { hydrateRoot } from 'react-dom/client';

const app = <BrowserApp/>;

const rootElement = document.getElementById('root');
if (window.ssrRendered) {
	console.log('hydrating');
	hydrateRoot(rootElement, app);
} else {
	console.log('rendering');
	const root = ReactDOM.createRoot(rootElement);
	root.render(app);
}