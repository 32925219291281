export default class EventSource {
	constructor() {
		this.listeners_ = [];
	}

	addListener(listener) {
		this.listeners_.push(listener);
	}

	removeListener(listener) {
		const index = this.listeners_.indexOf(listener);
		if (index > -1) {
			this.listeners_.splice(index, 1);
		}
	}

	fire(event) {
		this.listeners_.slice().forEach(listener => listener(event));
	}
}
