import React, { Component } from 'react';
import './Icon.scss';
import scroller from '../assets/scroller.svg';
import scrollerRight from '../assets/scroller-right.svg';
import close from '../assets/close.svg';
import play from '../assets/play.svg';
import download from '../assets/download.svg';
import pause from '../assets/pause.svg';
import rewind from '../assets/rewind.svg';
import forward from '../assets/forward.svg';

const icons = {
	scroller,
	scrollerRight,
	close,
	play,
	pause,
	rewind,
	forward,
	download,
};

export default class Icon extends Component {
	constructor(props) {
		super(props);
		const { type, black, ...otherProps } = props;
		this.otherProps = otherProps;
	}

	render() {
		return <div
			className={'icon' + (this.props.black ? ' icon-black' : '') + ' icon-' + this.props.type}
			{...this.otherProps}
			dangerouslySetInnerHTML={{ __html: icons[this.props.type] }}
		/>;
	}
}
