import React, { Component } from 'react';
import App from './App';
import { Router } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import { ScrollRestorerContext } from './ScrollRestorer';

export default class BrowserApp extends Component {
	constructor(props) {
		super(props);
		this.history = createHistory();
	}

	render() {
		return (
			<Router history={this.history}>
				<ScrollRestorerContext.Provider value={this.history}>
					<App contentsRef={this.appContentsRef_}/>
				</ScrollRestorerContext.Provider>
			</Router>
		);
	}
}
